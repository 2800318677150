import appConfig from './apiConfig';


//Api For Updating Participant
export const updateParticipantApi = (data) => {
    let participantData = JSON.stringify(data);
    const headers = Object.assign({
        'Content-Type': 'application/json',
    });
    let landingUrlString = encodeURIComponent(data.redirectedUrl);
    let serverUrl = appConfig.azureFunctions.PARTICIPANT_ROUTE_UPDATE;
    var allParams = "id=" + data.uid + "&checkLoi=" + data.checkLoi + "&sid=" + data.sid + "&status=" + data.status + "&redirectedUrl=" + landingUrlString;
    serverUrl = serverUrl.replace("{ALLPARAMS}", allParams);


    const request = new Request(serverUrl, {
        method: "GET",
        headers: headers,
        //body: participantData
    });

    return fetch(request).then(response => {
        return response.json().then(result => {
            return result;
        });
    }).catch(error => {
        return error;
    });
}



export const attemptRouterChainSurveyApi = (data) => {
    const headers = Object.assign({
        'Content-Type': 'application/json',
    });
    let landingUrlString = encodeURIComponent(data.landingUrl);
    let serverUrl = appConfig.azureFunctions.ATTEMPT_ROUTER_CHAIN_SURVEY;
    var allParams = "uid=" + data.uid + "&tid=" + data.tid + "&var3=" + data.var3  + "&userLandingUrl=" + landingUrlString;
    serverUrl = serverUrl.replace("{ALLPARAMS}", allParams);

    const request = new Request(serverUrl, {
        method: "GET",
        headers: headers,
    });

    return fetch(request).then(response => {
        return response.json().then(result => {
            return result;
        });
    }).catch(error => {
        return error;
    });
}



export const endRouterChainSurveyApi = (originPID) => {
    const headers = Object.assign({
        'Content-Type': 'application/json',
    });

    let endPoint = appConfig.azureFunctions.END_ROUTER_CHAIN;
    const allParams = "originPID=" + originPID;
    endPoint = endPoint.replace("{ALLPARAMS}", allParams);

    const request = new Request(endPoint, {
        method: "GET",
        headers: headers,
    });

    return fetch(request).then(response => {
        return response.json().then(result => {
            return result;
        });
    }).catch(error => {
        return error;
    });
}

export const getRedirectUrlForUserAttemptApi = (data) => {
    let participantData = JSON.stringify(data);
    const headers = Object.assign({
        'Content-Type': 'application/json',
    });
    const request = new Request(appConfig.azureFunctions.GET_REDIRECT_URL_FOR_USER_ATTEMPT, {
        method: "POST",
        headers: headers,
        body: participantData
    });

    return fetch(request).then(response => {
        return response.json().then(result => {
            return result;
        });
    }).catch(error => {
        return error;
    });
}


export const getRedirectAppMessageApi = (data) => {
    let participantData = JSON.stringify(data);
    const headers = Object.assign({
        'Content-Type': 'application/json',
    });

    let serverUrl = appConfig.azureFunctions.GET_UI_CONTENT;
    var allParams = "UID=" + data.uid + "&status=" + data.status;
    serverUrl = serverUrl.replace("{ALLPARAMS}", allParams);


    const request = new Request(serverUrl, {
        method: "GET",
        headers: headers,
        //body: participantData
    });

    return fetch(request).then(response => {
        return response.json().then(result => {
            return result;
        });
    }).catch(error => {
        return error;
    });
}

// noSurveryRequiredByUserApi
export const noSurveryRequiredByUserApi = (data) => {
    let declineUserData = JSON.stringify(data);
    const headers = Object.assign({
        'Content-Type': 'application/json',
    });
    const request = new Request(appConfig.azureFunctions.NO_SURVEY_REQUIRED_BY_USER, {
        method: "POST",
        headers: headers,
        body: declineUserData
    });

    return fetch(request).then(response => {
        return response.json().then(result => {
            return result;
        });
    }).catch(error => {
        return error;
    });
}

export const trackParticipantsApi = (url) => {
    const headers = Object.assign({
        'Content-Type': 'application/json',
    });
    let landingUrlString = encodeURIComponent(url);
    let endPoint = appConfig.azureFunctions.TRACK_PARTICIPANTS;
    const allParams = "appName=terminate&status=3&url=" + landingUrlString;
    endPoint = endPoint.replace("{ALLPARAMS}", allParams);
    const request = new Request(endPoint, {
        method: "GET",
        headers: headers,
    });
    return fetch(request).then(response => {
        return response.json().then(result => {
            return result;
        });
    }).catch(error => {
        return error;
    });
}


export const vendorRedirectUpdateApi = (data, redirectUrl) => {
    let participantData = JSON.stringify(data);
    const headers = Object.assign({
        'Content-Type': 'application/json',
    });
    let landingUrlString = encodeURIComponent(redirectUrl);
    let serverUrl = appConfig.azureFunctions.VENDOR_REDIRECT_UPDATE;
    var allParams = "pid=" + data.uid + "&redirectUrl=" + landingUrlString;
    serverUrl = serverUrl.replace("{ALLPARAMS}", allParams);


    const request = new Request(serverUrl, {
        method: "GET",
        headers: headers,
        //body: participantData
    });

    return fetch(request).then(response => {
        return response.json().then(result => {
            return result;
        });
    }).catch(error => {
        return error;
    });
}


export const clientUpdateRedirectUpdateApi = (data, redirectUrl) => {
        const headers = Object.assign({ 'Content-Type': 'application/json' });
        let landingUrlString = encodeURIComponent(redirectUrl);

        let serverUrl = appConfig.azureFunctions.CLIENT_REDIRECT_UPDATE;
        var allParams = "studyId=" + data.SID + "&pid=" + data.PID + "&redirectUrl=" + landingUrlString;
        serverUrl = serverUrl.replace("{ALLPARAMS}", allParams);


        debugger
        const request = new Request(serverUrl, {
            method: "GET",
            headers: headers,
            //body: participantData
        });
    
        return fetch(request).then(response => {
            return response.json().then(result => {
                return result;
            });
        }).catch(error => {
            return error;
        });

}
export const checkReRoutingUserApi = (pid, url) => {
    const headers = Object.assign({ 'Content-Type': 'application/json' });
    let redirectUrl = encodeURIComponent(url);
    let serverUrl = appConfig.azureFunctions.PARTICIPANT_ROUTING;
    var allParams = "pid=" +pid + "&redirectUrl=" + redirectUrl;
    serverUrl = serverUrl.replace("{ALLPARAMS}", allParams);
    const request = new Request(serverUrl, {
        method: "GET",
        headers: headers
    });

    return fetch(request).then(response => {
        return response.json().then(result => {
            return result;
        });
    }).catch(error => {
        return error;
    });

}