const initialState = {
    updateParticipantResp: ""
}

const successStore = (state = initialState, action) => {
    switch (action.type) {

        case "SET_UPDATE_PARTICIPANT_RESPONSE":
            return {
                ...state,
                updateParticipantResp: action.updateParticipantResp,
                attemptRouterChainSurvey : undefined,
                lastTerminate: null
            }

        case "SET_ATTEMPT_ROUTER_CHAIN_RESPONSE" :
            return {
                ...state,
                attemptRouterChainSurvey: action.attemptRouterChainSurvey,
                lastTerminate: null
            }

        case "SET_UPDATE_PARTICIPANT_REDIRECT_RESPONSE" :
            return {
                ...state,
                updateParticipantRouting : action.updateParticipantRouting,
                updateParticipantResp : null
            }

        case "SET_NO_SURVEY_REQUIRE" :
            return {
                ...state,
                lastTerminate: action.nosurveyreqResult,
                updateParticipantResp: null
            }

        case "SET_UI_CONTENT" :
            return {
                ...state,
                uiContent: action.uiContent
            }
        case "SET_TRACKING_RESPONSE" :
            return{
                ...state,
                trackingResponse : action.response
            }
        case "SET_END_ROUTER_CHAIN_SURVEY_RESPONSE" :
            return{
                ...state,
                endRouterChainSurvey : action.endRouterChainSurvey
            }
            
        default:
            return state;
    }

};
export default successStore;
  