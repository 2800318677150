let zampliaKey='oZITb9ktSIbQ0s1lRncggvGuuSCukv3GQ5qTpgEuWv8l1m4HKBcMuQ==';

// Webapp
let BASE_URL = process.env.REACT_APP_API_URL;

let isDevEnv = false;
let queryString = window.location.search;

if (queryString && queryString.includes("ZLZP")) {
    isDevEnv = true;
}

if(isDevEnv){
    BASE_URL = process.env.REACT_APP_API_URL_STAGING;
}

export const PARTICIPANT_ROUTE_UPDATE = BASE_URL + "participantUpdateV2?{ALLPARAMS}&code="+zampliaKey;
export const GET_UI_CONTENT = BASE_URL+ "getRedirectAppMessage?{ALLPARAMS}&code="+zampliaKey;
export const NO_SURVEY_REQUIRED_BY_USER = BASE_URL + "noSurveryRequiredByUser?code="+zampliaKey;
export const VENDOR_REDIRECT_UPDATE = BASE_URL+"vendorRedirectUpdate?{ALLPARAMS}&code="+zampliaKey;
export const TRACK_PARTICIPANTS = BASE_URL + "addParticipantsToTrack?{ALLPARAMS}&code="+zampliaKey;
export const ATTEMPT_ROUTER_CHAIN_SURVEY = BASE_URL + "attemptRouterChainSurvey?{ALLPARAMS}&code="+zampliaKey;
export const END_ROUTER_CHAIN = BASE_URL+"endRouterChain?{ALLPARAMS}&code="+zampliaKey;
export const GET_REDIRECT_URL_FOR_USER_ATTEMPT = BASE_URL+"getRedirectUrlForUserAttempt?code="+zampliaKey;
export const CLIENT_REDIRECT_UPDATE = BASE_URL + "clientRedirectUpdate?{ALLPARAMS}&code="+zampliaKey;
export const PARTICIPANT_ROUTING = BASE_URL + "checkParticipantReRouting?{ALLPARAMS}&code="+zampliaKey;

