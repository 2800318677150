import React from "react";
import { render } from "react-dom";
import "antd/dist/antd.css";
import "./index.css";
import App from "./App";
import { Provider } from "react-redux";
import { store } from "./lib/store";
import { IntlProvider } from "react-intl";

import * as serviceWorker from "./serviceWorker";

import messages_en from "./translations/en.json";
import messages_fr from "./translations/fr.json";
import messages_es from "./translations/es.json";
import messages_it from "./translations/it.json";
import messages_de from "./translations/de.json";
import messages_zh from "./translations/zh.json";
import messages_ja from "./translations/ja.json";
import messages_pt from "./translations/pt.json";
import messages_pl from "./translations/pl.json";
import messages_ru from "./translations/ru.json";
import messages_ml from "./translations/ml.json";
import messages_nl from "./translations/nl.json";
import messages_tr from "./translations/tr.json";
import messages_hi from "./translations/hi.json";
import messages_id from "./translations/id.json";
import messages_ko from "./translations/ko.json";
import messages_fil from "./translations/fil.json";
import messages_th from "./translations/th.json";
import messages_ar from "./translations/ar.json";
import messages_sw from "./translations/sw.json";
import messages_af from "./translations/af.json";
import messages_sv from "./translations/sv.json";
import messages_uk from "./translations/uk.json";

const messages = {
  fr: messages_fr,
  en: messages_en,
  es: messages_es,
  it: messages_it,
  de: messages_de,
  zh: messages_zh,
  ja: messages_ja,
  pt: messages_pt,
  pl: messages_pl,
  ru: messages_ru,
  ml: messages_ml,
  nl: messages_nl,
  tr: messages_tr,
  hi: messages_hi,
  id: messages_id,
  ko: messages_ko,
  fil: messages_fil,
  th: messages_th,
  ar: messages_ar,
  sw: messages_sw,
  af: messages_af,
  sv: messages_sv,
  uk: messages_uk
};

let language = "en";
let browserLang = navigator.language.split(/[-_]/)[0];
if (browserLang === "fr") {
  language = "fr";
} else if (browserLang === "es") {
  language = "es";
} else if (browserLang === "it") {
  language = "it";
} else if (browserLang === "de") {
  language = "de";
} else if (browserLang === "zh") {
  language = "zh";
} else if (browserLang === "ja") {
  language = "ja";
} else if (browserLang === "pt") {
  language = "pt";
} else if (browserLang === "pl") {
  language = "pl";
} else if (browserLang === "ru") {
  language = "ru";
} else if (browserLang === "ml") {
  language = "ml";
} else if (browserLang === "nl") {
  language = "nl";
} else if (browserLang === "tr") {
  language = "tr";
} else if (browserLang === "hi") {
  language = "hi";
} else if (browserLang === "id") {
  language = "id";
} else if (browserLang === "ko") {
  language = "ko";
} else if (browserLang === "fil") {
  language = "fil";
} else if (browserLang === "th") {
  language = "th";
} else if (browserLang === "ar") {
  language = "ar";
} else if (browserLang === "sw") {
  language = "sw";
} else if (browserLang === "af") {
  language = "af";
} else if (browserLang === "sv") {
  language = "sv";
} else if (browserLang === "uk") {
  language = "uk";
}
console.log("hello am testing niraj pandey")
render(
  <Provider store={store}>
    <IntlProvider locale={language} messages={messages[language]}>
      <App />
    </IntlProvider>
  </Provider>,
  document.getElementById("root")
);

serviceWorker.unregister();
